import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { space } from 'styled-system';

import Logo from '../assets/icons/primech-white.svg';

import {  LANG_ZH } from '../config/langSetting';
import Container from './Atoms/Container';
import Row from './Atoms/Row';
import Column from './Atoms/Column';
import Box from './Atoms/Box';
import SiteMap from './SiteMap';
import Paragraph from './Atoms/Paragraph';
import theme from '../styles/theme';
import CustomLink from './Atoms/CustomLink';

const StyledLogo = styled(Logo)`
  width: 160px;
  & > path {
    fill: #ffffff;
  }
`


const StyledFooter = styled.footer`
  && {
    background-color: ${props => props.theme.color.darkBlue};
    ${space}
  }
`;

class FooterUI extends React.Component {
  render() {
    const { data, pathname } = this.props;
    const { footer, copyright } = data.markdownRemark.frontmatter;

    return (
      <StyledFooter className="footer" pt={[6, 6, 7]} pb={3}>
        <Container>
          <Row multi>
            <Column col="is-12">
              <Box width={140}>
                <StyledLogo />
              </Box>
            </Column>
            <Column col="is-12" pb={7}>
              <SiteMap footer={footer} />
            </Column>
          </Row>
          <Row>
            <Column>
              <Box width="100%" borderTop="1px solid #01446c" pt={3} pb={1} textAlign="left">
                <Box as="span" mr={2}>
                  <Paragraph color={theme.color.white} textAlign="center" fontSize={0} pb={0}>
                    {copyright}
                  </Paragraph>
                </Box>
              </Box>
            </Column>
          </Row>
        </Container>

      </StyledFooter>
    );
  }
}


export const footerQuery = graphql`
  query SiteMapPageQuery {
    markdownRemark(frontmatter: { component: { eq: "footer" } }) {
      frontmatter {
        copyright
        footer {
          title
          path
          links {
            label
            path
          }
        }
      }
    }
  }
`;


class Footer extends React.PureComponent {
  render() {
    return (
      <StaticQuery
        query={footerQuery}
        render={data => <FooterUI data={data} />}
      />
    );
  }
}

export default Footer;
