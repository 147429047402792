import React from 'react';
import styled from 'styled-components';

import Row from './Atoms/Row';
import Column from './Atoms/Column';
import Box from './Atoms/Box';
import CustomLink from './Atoms/CustomLink';
import theme from '../styles/theme';

const LinkWord = styled.div`
  font-size: 14px;
  color: ${props => props.theme.color.white};
  transition: color ease-in-out 100ms;
  margin-bottom: 16px;
  &:hover {
    color: ${props => props.theme.color.blue};
  }
`;

function SiteMap({ footer }) {
  return (
    <Row mobile multi justifyContent={['flex-start', 'flex-start']}>
      {footer.map(category => (
        <Column col="is-half-mobile is-2-tablet" key={category.title}>
          <CustomLink to={category.path || ''}>
            <LinkWord>{category.title || ''}</LinkWord>
          </CustomLink>
          {category.links && category.links.map(({ label, path }) => (
            <Box pb={1} key={label}>
              <CustomLink to={path}>
                <LinkWord>{label}</LinkWord>
              </CustomLink>
            </Box>
          ))}
        </Column>
      ))}
    </Row>
  );
}

export default SiteMap;
