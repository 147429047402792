import { FormattedMessage } from 'react-intl';
import React from 'react';

export const pageUrls = {
  home: {
    url: '/',
    title: <FormattedMessage id="pageUrl:home" defaultMessage="Home" />,
  },
  about: {
    url: 'about',
    title: <FormattedMessage id="pageUrl:about" defaultMessage="About" />,
  },
  our_business: {
    url: 'about/our_business',
    title: <FormattedMessage id="pageUrl:about.our_business" defaultMessage="Our Business" />,
  },
  corporate_profile: {
    url: 'about/corporate_profile',
    title: <FormattedMessage id="pageUrl:about.corporate_profile" defaultMessage="Corporate Profile" />,
  },
  innovation: {
    url: 'innovation',
    title: <FormattedMessage id="pageUrl:innovation" defaultMessage="Innovation" />,
  },
  investor_relation: {
    url: 'investor_relations',
    title: <FormattedMessage id="pageUrl:investor_relation" defaultMessage="Investor Relation" />,
  },
  contact: {
    url: 'contact',
    title: <FormattedMessage id="pageUrl:contact" defaultMessage="Contact Us" />,
  },
  client: {
    url: 'clients',
    title: <FormattedMessage id="pageUrl:client" defaultMessage="Clients" />,
  },
  career: {
    url: 'career',
    title: <FormattedMessage id="pageUrl:career" defaultMessage="Careers" />,
  },
  articles: {
    url: 'articles',
    title: <FormattedMessage id="pageUrl:articles" defaultMessage="Articles" />,
  },
  articles_financial_reports: {
    url: 'articles/financial_reports',
    title: <FormattedMessage id="pageUrl:financial_reports" defaultMessage="Financial Reports" />,
  },
  articles_announcements: {
    url: 'articles/announcements',
    title: <FormattedMessage id="pageUrl:articles_announcements" defaultMessage="Announcements" />,
  },
  news: {
    url: 'articles/news',
    title: <FormattedMessage id="pageUrl:news" defaultMessage="News" />,
  },
};
