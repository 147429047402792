import React from 'react';
import { Link } from 'gatsby';
import styled, { css } from 'styled-components';
import { space } from 'styled-system';
import { observer, inject } from 'mobx-react';
import { observable } from 'mobx';

import { pageUrls } from '../config/pageSetting';
import { DEFAULT_LANG } from '../config/langSetting';
import Box from './Atoms/Box';
import Container from './Atoms/Container';

import Logo from '../assets/icons/primech.svg';
import FlexBox from './Atoms/FlexBox';

const LogoLink = styled(Link)`
  padding-left: 4px;
`;

const NavbarWrapper = styled.nav`
  && {
    transition: background-color 100ms ease-in;
    background-color: rgba(256, 256, 256);
    border-bottom: 1px solid ${({ theme }) => theme.color.midGray};
    @media screen and (min-width: 768px) {
      ${props => props.hasBackground
        && css`
          background-color: rgb(256, 256, 256); /* The Fallback */
          background-color: rgba(256, 256, 256, 0.95);
        `}
    }
  }
  ${space}

  .navbar-item {
    font-size: 16px;
  }

  .navbar-dropdown {
    .navbar-item {
      text-transform: none;
      font-size: 16px;
    }
  }
`;

const NewStyledNavbarLink = styled.ul`
  && {
    text-align: left;
    text-transform: capitalize;
    transition: all 100ms ease-in;
    padding: 16px 24px;
    border-bottom: 1px solid #f8f8f8;
    &:hover,
    &.active {
      color: ${props => props.theme.color.white};
    }
    @media screen and (min-width: 768px) {
      text-transform: uppercase;
      border-bottom: 5px solid transparent;
      padding: 8px 24px;
      &:hover,
      &.active {
        //font-weight: 700;
        border-color: ${props => props.theme.color.white};
      }
    }

    span {
      padding-top: 8px;
      padding-bottom: 8px;
    }

    &.navbar-sub-item {
      padding: 16px 24px;
      border-color: transparent;
      span {
        border-bottom: 1px solid transparent;
        padding-top: 16px;
        padding-bottom: 16px;
      }
      &:hover,
      &.active {
        span {
          border-color: ${props => props.theme.color.white};
        }
      }

      @media screen and (min-width: 768px) {
        padding: 8px 24px;
        span {
          padding-top: 8px;
          padding-bottom: 8px;
          border-width: 5px;
        }
      }
    }
  }
`;

const StyledNavbarLink = styled(Link)`
  && {
    text-align: left;
    text-transform: capitalize;
    transition: all 100ms ease-in;
    padding: 16px 24px;
    border-bottom: 1px solid #f8f8f8;
    &:hover,
    &.active {
      color: ${props => props.theme.color.white};
    }
    @media screen and (min-width: 768px) {
      text-transform: uppercase;
      border-bottom: 5px solid transparent;
      padding: 8px 24px;
      &:hover,
      &.active {
        //font-weight: 700;
        border-color: ${props => props.theme.color.white};
      }
    }

    span {
      padding-top: 8px;
      padding-bottom: 8px;
    }

    &.navbar-sub-item {
      padding: 16px 24px;
      border-color: transparent;
      span {
        border-bottom: 1px solid transparent;
        padding-top: 16px;
        padding-bottom: 16px;
      }
      &:hover,
      &.active {
        span {
          border-color: ${props => props.theme.color.white};
        }
      }

      @media screen and (min-width: 768px) {
        padding: 8px 24px;
        span {
          padding-top: 8px;
          padding-bottom: 8px;
          border-width: 5px;
        }
      }
    }
  }
`;

const StyledBrand = styled.div`
  ${space}
  a:focus-within {
    background-color: transparent;
  }
`;

@observer
class Navbar extends React.Component {
  @observable active = false;

  @observable navBarActiveClass = '';

  @observable scrolledDown = false;

  toggleHamburger = () => {
    // toggle the active boolean in the state
    this.active = !this.active;
    this.navBarActiveClass = this.active ? 'is-active' : '';
  };

  closeHamburger = () => {
    // toggle the active boolean in the state
    this.active = false;
    this.navBarActiveClass = '';
  };

  handleScroll = (e) => {
    const scrollTop = window.pageYOffset;
    this.scrolledDown = scrollTop > 20;
  };

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll, true);
  }

  render() {
    const { lang, pathname } = this.props;
    const pathPrefix = lang === DEFAULT_LANG ? '' : `/${lang}`;

    return (
      <NavbarWrapper
        className="navbar is-fixed-top is-transparent"
        role="navigation"
        aria-label="main-navigation"
        hasBackground={this.scrolledDown}
        py={0}
        px={[0, 0, 3]}
      >
        <Container>
          <StyledBrand className="navbar-brand" px={2}>
            <LogoLink to={`${pathPrefix}`} className="navbar-item" onClick={this.closeHamburger}>
              <FlexBox width={[100, 100, 120]} height="auto">
                <Logo />
              </FlexBox>
            </LogoLink>
            {/* Hamburger menu */}
            <div
              className={`navbar-burger burger ${this.navBarActiveClass}`}
              data-target="navMenu"
              onClick={this.toggleHamburger}
            >
              <span />
              <span />
              <span />
            </div>
          </StyledBrand>
          <div id="navMenu" className={`navbar-menu ${this.navBarActiveClass}`}>
            <div className="navbar-end has-text-centered">
              <div className="navbar-item has-dropdown is-hoverable ">
                {/*<StyledNavbarLink*/}
                {/*  onClick={this.closeHamburger}*/}
                {/*  className={`navbar-link is-arrowless ${*/}
                {/*    pathname.indexOf(pageUrls.about.url) !== -1 ? 'active' : ''*/}
                {/*  }`}*/}
                {/*  to={`${pathPrefix}${pageUrls.our_business.url}`}*/}
                {/*>*/}
                {/*  <Box>{pageUrls.about.title}</Box>*/}
                {/*</StyledNavbarLink>*/}
                <NewStyledNavbarLink>
                  <a href="https://www.primechholdings.com/about/our_business" target="_self" class="navbar-item is-hoverable">ABOUT</a>
                </NewStyledNavbarLink>
                <Box className="navbar-dropdown is-right" px={2}>
                  <NewStyledNavbarLink>
                    <a href="https://www.primechholdings.com/about/our_business" target="_self" class="navbar-item is-hoverable">Our Business</a>
                  </NewStyledNavbarLink>
                  <NewStyledNavbarLink>
                    <a href="https://www.primechholdings.com/about/corporate_profile" target="_self" class="navbar-item is-hoverable">Corporate Profile</a>
                  </NewStyledNavbarLink>
                  {/*<StyledNavbarLink*/}
                  {/*  onClick={this.closeHamburger}*/}
                  {/*  className={`navbar-item navbar-sub-item ${*/}
                  {/*    pathname.indexOf(pageUrls.our_business.url) !== -1 ? 'active' : ''*/}
                  {/*  }`}*/}
                  {/*  to={`${pathPrefix}${pageUrls.our_business.url}`}*/}
                  {/*>*/}
                  {/*  {pageUrls.our_business.title}*/}
                  {/*</StyledNavbarLink>*/}
                  {/*<StyledNavbarLink*/}
                  {/*  onClick={this.closeHamburger}*/}
                  {/*  className={`navbar-item navbar-sub-item ${*/}
                  {/*    pathname.indexOf(pageUrls.corporate_profile.url) !== -1 ? 'active' : ''*/}
                  {/*  }`}*/}
                  {/*  to={`${pathPrefix}${pageUrls.corporate_profile.url}`}*/}
                  {/*>*/}
                  {/*  {pageUrls.corporate_profile.title}*/}
                  {/*</StyledNavbarLink>*/}
                  {/*<StyledNavbarLink*/}
                  {/*  onClick={this.closeHamburger}*/}
                  {/*  className={`navbar-item navbar-sub-item ${*/}
                  {/*    pathname.indexOf(pageUrls.client.url) !== -1 ? 'active' : ''*/}
                  {/*  }`}*/}
                  {/*  to={`${pathPrefix}${pageUrls.client.url}`}*/}
                  {/*>*/}
                  {/*  {pageUrls.client.title}*/}
                  {/*</StyledNavbarLink>*/}
                </Box>
              </div>
              <NewStyledNavbarLink>
                <a href="https://www.primechholdings.com/innovation" target="_self" class="navbar-item is-hoverable">INNOVATION</a>
              </NewStyledNavbarLink>
              <NewStyledNavbarLink>
                <a href="https://investor.primechholdings.com" target="_self" class="navbar-item is-hoverable">INVESTOR RELATIONS</a>
              </NewStyledNavbarLink>
              <NewStyledNavbarLink>
                <a href="https://www.primechholdings.com/articles/news" target="_self" class="navbar-item is-hoverable">NEWS</a>
              </NewStyledNavbarLink>
              <NewStyledNavbarLink>
                <a href="https://www.primechholdings.com/contact" target="_self" class="navbar-item is-hoverable">CONTACT US</a>
              </NewStyledNavbarLink>
              {/*<StyledNavbarLink*/}
              {/*  onClick={this.closeHamburger}*/}
              {/*  className={`navbar-item ${*/}
              {/*    pathname.indexOf(pageUrls.innovation.url) !== -1 ? 'active' : ''*/}
              {/*  }`}*/}
              {/*  to={`${pathPrefix}${pageUrls.innovation.url}`}*/}
              {/*>*/}
              {/*  {pageUrls.innovation.title}*/}
              {/*</StyledNavbarLink>*/}
              {/*<StyledNavbarLink*/}
              {/*  onClick={this.closeHamburger}*/}
              {/*  className={`navbar-item ${*/}
              {/*    pathname.indexOf(pageUrls.investor_relation.url) !== -1 ? 'active' : ''*/}
              {/*  }`}*/}
              {/*  to={`${pathPrefix}${pageUrls.investor_relation.url}`}*/}
              {/*>*/}
              {/*  {pageUrls.investor_relation.title}*/}
              {/*</StyledNavbarLink>*/}
              {/*<StyledNavbarLink*/}
              {/*  onClick={this.closeHamburger}*/}
              {/*  className={`navbar-item ${*/}
              {/*    pathname.indexOf(pageUrls.news.url) !== -1 ? 'active' : ''*/}
              {/*  }`}*/}
              {/*  to={`${pathPrefix}${pageUrls.news.url}`}*/}
              {/*>*/}
              {/*  {pageUrls.news.title}*/}
              {/*</StyledNavbarLink>*/}
              {/*<StyledNavbarLink*/}
              {/*  onClick={this.closeHamburger}*/}
              {/*  className={`navbar-item ${*/}
              {/*    pathname.indexOf(pageUrls.contact.url) !== -1 ? 'active' : ''*/}
              {/*  }`}*/}
              {/*  to={`${pathPrefix}${pageUrls.contact.url}`}*/}
              {/*>*/}
              {/*  {pageUrls.contact.title}*/}
              {/*<StyledNavbarLink>*/}
            </div>
          </div>
        </Container>
      </NavbarWrapper>
    );
  }
}

export default Navbar;
