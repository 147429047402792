const theme = {
  space: [0, 4, 8, 16, 24, 32, 40, 64, 128, 256, 512],
  breakpoints: ['576px', '768px', '992px', '1200px'],
  fontSizes: [12, 14, 16, 18, 24, 28, 32, 48, 64, 72],
  color: {
    darkBlue: '#01253e',
    lightBlack: '#353535',
    blue: '#78a2d3',
    lightBlue: '#eff8ff',
    greyish: '#aaaaaa',
    midGray: '#e8e8e8',
    white: 'white',
    red: '#eb5757',
    black: '#000000',
    black44: 'rgba(0,0,0,0.44)',
    black80: 'rgba(0,0,0,0.8)',
  },
};

export default theme;
